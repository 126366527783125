const axios = require("axios");

const isRetryableError = (error) => {
  return (
    error?.code === "ECONNABORTED" || // Socket hang-up
    error?.code === "ENOTFOUND" || // Network unavailable (DNS issues)
    error?.message?.includes("Network Error") // General network error
  );
};

export const axiosWithRetry = async (config, retries = 3, delay = 500) => {
  let attempts = 0;

  while (attempts < retries) {
    try {
      const response = await axios(config);
      return response;
    } catch (error) {
      attempts++;
      if (isRetryableError(error)) {
        if (attempts >= retries) {
          Promise.reject(
            new Error(
              `Request failed after ${retries} attempts: ${error.message} ${error.code}`
            )
          );
        }
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        throw error;
      }
    }
  }
};
