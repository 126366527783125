import React, { useState, useMemo, useEffect, useRef } from "react";
import jwt_decode from "jwt-decode";
import ResponseModal from "../components/ResponseModal";
import Loader from "../components/Loader";
import {
  completeKYCFlow,
  getKycDetails,
  getWebSDKVersions,
} from "../utils/apiRepository";
import { axiosWithRetry } from "../utils/axiosRetry";
import { updateRedirectUrlQueryParams } from "../utils/redirectUrlFormatter";
import * as Sentry from "@sentry/react";
import "./container.scss";

const LinkKyc = () => {
  const [loading, setLoading] = useState(true);
  const [identifier, setIdentifier] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [redirectURL, setredirectURL] = useState("");
  const [workflowId, setWorkflowId] = useState("");
  const [jwtToken, setJwtToken] = useState("");
  const [appId, setAppId] = useState("");
  const [inputs, setInputs] = useState({});
  const [defaultLanguage, setDefaultLanguage] = useState({});
  const [responseReceived, setResponseReceived] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [redirectTime, setRedirectTime] = useState(5);
  const [showGetStarted, setShowGetStarted] = useState(true);
  const [enableDarkMode, setEnableDarkMode] = useState(false);
  const [useLocation, setUseLocation] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [customFontStylesheet, setCustomFontStylesheet] = useState("");
  const [containerWidth, setContainerWidth] = useState(350);
  const [versionForRetryButton, setVersionForRetryButton] = useState("v2");
  const [versionForFooter, setVersionForFooter] = useState("v2");
  const [loaderColor, setInitialLoaderColor] = useState("#554ef1");
  const [versionForLoaderSize, setVersionForLoaderSize] = useState("v2");
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [showCustomEndScreen, setShowCustomEndScreen] = useState(false);
  const [linkExpired, setLinkExpired] = useState(false);
  const responseStatusRef = useRef();
  const showCustomEndScreenRef = useRef();
  const [supportCustomEndScreen, setSupportCustomEndScreen] = useState(false);
  // Use refs for states to ensure consistency in SDK callbacks.
  responseStatusRef.current = responseStatus;
  showCustomEndScreenRef.current = showCustomEndScreen;

  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  const isLocalStorageAvailable = () => {
    let test = "test";
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (err) {
      // Capture the exception with detailed context in Sentry
      Sentry.captureException(err, {
        tags: {
          source: "isLocalStorageAvailable",
          origin: "localStorage",
        },
        extra: {
          source: "isLocalStorageAvailable",
          identifier: queryParams.get("identifier"),
          appId,
          message: err.message,
          stack: err.stack,
        },
        level: "error",
      });

      return false;
    }
  };

  const getDomain = () => {
    const urlParts = window.location.hostname.split(".");
    return urlParts.slice(0).slice(-2).join(".");
  };

  const getHostname = () => {
    return window.location.hostname;
  };

  const fetchScriptManually = async (sdkUrl, cb) => {
    const response = await axiosWithRetry({
      method: "get",
      url: sdkUrl,
    });
    const existingScript = document.getElementById("websdk-script");
    if (existingScript) {
      existingScript.remove();
    }
    window.scriptLoadedcb = () => {
      cb();
    };
    // Append the script to the document head
    const scriptElement = document.createElement("script");
    scriptElement.id = "websdk-script";
    scriptElement.type = "text/javascript";
    scriptElement.textContent = [response.data, "scriptLoadedcb();"].join("\n");
    document.head.append(scriptElement);
  };

  const setCookie = (name, value, days) => {
    const date = new Date();
    const domain = getDomain();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; domain=${domain}; ${expires}; path=/;`;
  };

  const fetchUrlDetails = async () => {
    try {
      // get details from queryParams
      const urlIdentifier = queryParams.get("identifier");
      if (!urlIdentifier) {
        throw new Error("Identifier not found");
      }
      setIdentifier(urlIdentifier);
      const showCustomEndScreenEnabled = queryParams.get("showCustomEndScreen");
      setShowCustomEndScreen(showCustomEndScreenEnabled === "yes");
      const identifier = urlIdentifier.split("_");
      identifier.shift();
      const transactionId = identifier.join("_");
      setTransactionId(transactionId);

      // fetch from API
      const details = await getKycDetails(urlIdentifier);
      setredirectURL(details.redirectUrl);
      setJwtToken(details.jwtToken);
      setUseLocation(details.setUseLocation);
      setUniqueId(details.setUniqueId);
      setCustomFontStylesheet(details.setCustomFontStylesheet);
      const decodedToken = jwt_decode(details.jwtToken);
      setAppId(decodedToken.appId);
      setWorkflowId(details.workflowId);
      setInputs(details.inputs);
      setDefaultLanguage(details.defaultLanguage || "en");
      setRedirectTime(details.redirectTime);
      setShowGetStarted(details.showGetStartedScreen);
      return { appId: decodedToken.appId };
    } catch (err) {
      if (
        err?.message != "Identifier not found" &&
        process.env.REACT_APP_SENTRY_DSN
      ) {
        Sentry.captureException(err, {
          tags: {
            source: "customVersionLinkKycFetchDetails",
          },
          extra: {
            source: "customVersionLinkKycFetchDetails",
            identifier: queryParams.get("identifier"),
          },
        });
      }
      if (
        err.response &&
        err.response.status === 400 &&
        err.response.data &&
        err.response.data.result.error.message === "Link expired"
      ) {
        setResponseStatus(`invalid_${err.response.data.result.error.status}`);
        setredirectURL(err.response.data.result.error.redirectUrl);
        setLinkExpired(true);
        setRedirectTime(err.response.data.result.error.redirectTime);
        const token = err.response.data.result.error?.jwtToken;
        setJwtToken(token);
        const decodedToken = jwt_decode(token);
        setAppId(decodedToken.appId);
        setWorkflowId(err.response.data.result.error.workflowId);
        if (showCustomEndScreenRef.current) {
          setResponseReceived(true);
          return { appId: decodedToken.appId };
        }
      } else {
        setResponseStatus("error");
      }
      setResponseReceived(true);
      if (!showCustomEndScreenRef.current) setLoading(false);
    }
  };

  const fetchWebSDKVersions = async () => {
    try {
      const versions = await getWebSDKVersions();
      return versions;
    } catch (err) {
      if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.captureException(err, {
          tags: {
            source: "customVersionLinkKycFetchSDKVersion",
          },
          extra: {
            source: "customVersionLinkKycFetchSDKVersion",
            identifier: queryParams.get("identifier"),
          },
        });
      }
      setResponseReceived(true);
      setResponseStatus("error");
      setLoading(false);
    }
  };

  const calculateVersionToUse = (sdkVersionConfig, appId) => {
    if (
      localStorage.getItem("sdkVersion") &&
      localStorage.getItem("selector")
    ) {
      return [
        localStorage.getItem("sdkVersion"),
        localStorage.getItem("selector"),
      ];
    }
    const weight = Math.random();
    if (sdkVersionConfig?.[appId]) {
      const appIdWeight = Number(sdkVersionConfig[appId]["weight"]);
      return weight < appIdWeight
        ? [sdkVersionConfig[appId]["version"], appId]
        : [sdkVersionConfig["default"]["version"], "default"];
    } else if (sdkVersionConfig?.globalCanary) {
      const globalCanaryWeight = Number(
        sdkVersionConfig["globalCanary"]["weight"]
      );
      return weight < globalCanaryWeight
        ? [sdkVersionConfig["globalCanary"]["version"], "globalCanary"]
        : [sdkVersionConfig["default"]["version"], "default"];
    } else {
      return [sdkVersionConfig["default"]["version"], "default"];
    }
  };

  const getSdkDomain = (sdkConfig, selector) =>
    sdkConfig?.[selector]?.useCDN === "yes"
      ? "https://hv-web-sdk-cdn.hyperverge.co"
      : "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com";

  const getScriptUrl = (version, baseUrl) =>
    `${baseUrl}/hyperverge-web-sdk@${version}/src/sdk.min.js`;

  const performInitOperations = async () => {
    const [urlDetails, sdkVersionConfig] = await Promise.all([
      fetchUrlDetails(),
      fetchWebSDKVersions(),
    ]);

    const [versionToUse, selector] = calculateVersionToUse(
      sdkVersionConfig,
      urlDetails?.appId || appId // support fetching SDK when LinkExpired
    );
    localStorage.setItem("sdkVersion", versionToUse);
    localStorage.setItem("selector", selector);
    console.log("Using version ", versionToUse);
    const sdkBaseDomain = getSdkDomain(sdkVersionConfig, selector);
    const sdkUrl = getScriptUrl(versionToUse, sdkBaseDomain);
    const script = document.createElement("script");
    script.setAttribute("id", "websdk-script");
    script.src = sdkUrl;
    script.onload = () => {
      setLoading(false);
    };
    script.onerror = async (error) => {
      if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.captureException(error, {
          tags: { source: "sdkScriptLoadingFailure" },
          extra: {
            source: "sdkScriptLoadingFailure",
            identifier: queryParams.get("identifier"),
            error: error,
            version: versionToUse,
            hostName: getHostname(),
            attempt: 1,
          },
          level: "error",
        });
      }
      try {
        const cb = () => {
          setLoading(false);
        };
        // Attempt to fetch the script manually via axios
        await fetchScriptManually(sdkUrl, cb);
      } catch (err) {
        if (process.env.REACT_APP_SENTRY_DSN) {
          Sentry.captureException(err, {
            tags: { source: "sdkScriptLoadingFailure" },
            extra: {
              source: "sdkScriptLoadingFailure",
              identifier: queryParams.get("identifier"),
              error: err,
              version: versionToUse,
              hostName: getHostname(),
              attempt: 2,
            },
            level: "error",
          });
        }
      }
    };
    document.body.appendChild(script);
    setContainerWidth(
      isVersionGreaterOrEqual(versionToUse, "8.4.1") ? 396 : 350
    );

    setVersionForRetryButton(
      isVersionGreaterOrEqual(versionToUse, "8.1.0") ? "v2" : "v1"
    );

    setVersionForFooter(
      isVersionGreaterOrEqual(versionToUse, "8.4.1") ? "v2" : "v1"
    );

    setVersionForLoaderSize(
      isVersionGreaterOrEqual(versionToUse, "8.5.0") ? "v2" : "v1"
    );
    setSupportCustomEndScreen(isVersionGreaterOrEqual(versionToUse, "8.11.0"));
  };

  const isVersionGreaterOrEqual = (version1, version2) => {
    if (version1 === "stg-demo") return true;
    const [major1, minor1, patch1] = version1
      .split(".")
      .map((part) => parseInt(part));
    const [major2, minor2, patch2] = version2
      .split(".")
      .map((part) => parseInt(part));
    if (major1 !== major2) return major1 > major2;
    if (minor1 !== minor2) return minor1 > minor2;
    return patch1 >= patch2;
  };

  const callback = async (HyperKycResult) => {
    try {
      delete HyperKycResult.details;
      console.log("HYPERKYC RESULT", HyperKycResult);
      if (!showCustomEndScreenRef.current) setResponseReceived(true);
      window.HyperSnapSDK.endUserSession();
      if (
        HyperKycResult.errorCode &&
        HyperKycResult.errorCode === 401 &&
        HyperKycResult.errorMessage === "Token Expired"
      )
        setResponseStatus("expired");
      else setResponseStatus(HyperKycResult.status);

      if (
        HyperKycResult.status !== "error" &&
        HyperKycResult.status !== "user_cancelled"
      ) {
        await completeKYCFlow(identifier, HyperKycResult.status);
      }
    } catch (err) {
      if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.captureMessage(
          `Error captured for ${transactionId}, SDK response: ${JSON.stringify(
            HyperKycResult
          )}`,
          { level: "info" }
        );
        Sentry.captureException(err);
      }
      throw err;
    }
    localStorage.removeItem("sdkVersion");
    localStorage.removeItem("selector");
    if (!showCustomEndScreenRef.current) setLoading(false);
  };

  const startKYC = async () => {
    try {
      localStorage.removeItem("eventType");
      const config = new window.HyperKycConfig(
        jwtToken,
        workflowId,
        transactionId,
        showGetStarted
      );
      const sdkConfigMethods = {
        setUseLocation: useLocation,
        setUniqueId: uniqueId,
        setCustomFontStylesheet: customFontStylesheet,
        setInitialLoaderColor: loaderColor,
        supportDarkMode: enableDarkMode,
      };

      Object.entries(sdkConfigMethods).forEach(([method, value]) => {
        if (typeof config?.[method] === "function") {
          config[method](value);
        }
      });
      config.setInputs(inputs);
      config.setDefaultLangCode(defaultLanguage);

      if (supportCustomEndScreen) {
        const endScreenMethods = {
          setShowCustomEndScreen: showCustomEndScreen,
          setIsLinkExpired: linkExpired,
          setLinkKYCRedirectionTime: redirectTime,
          setLinkKYCRedirection: RedirectfromSDK,
        };

        Object.entries(endScreenMethods).forEach(([method, value]) => {
          if (typeof config?.[method] === "function") {
            config[method](value);
          }
        });
      } else {
        setShowCustomEndScreen(false); // disable custom End Screen if not supported by SDK
      }

      window.HyperKYCModule.launch(config, callback);
    } catch (err) {
      if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.captureException(err);
      }
      setResponseReceived(true);
      setResponseStatus("error");
    }
    setLoading(true);
  };

  const isInDarkMode = () => {
    return (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    );
  };

  const updateStyle = () => {
    const darkMode = queryParams.get("enableDarkMode");
    const darkThemeLoaderColor = queryParams.get("darkThemeLoaderColor");
    const lightThemeLoaderColor = queryParams.get("lightThemeLoaderColor");
    const queryBackgroundColor = queryParams.get("backgroundColor");
    if (darkMode === "yes" && isInDarkMode()) {
      setEnableDarkMode(true);
      localStorage.setItem("darkMode", "yes");
      setInitialLoaderColor(darkThemeLoaderColor || loaderColor);
    } else {
      setEnableDarkMode(false);
      localStorage.setItem("darkMode", "no");
      setInitialLoaderColor(lightThemeLoaderColor || loaderColor);
      setBackgroundColor(queryBackgroundColor || backgroundColor);
    }
  };

  useEffect(() => {
    isLocalStorageAvailable();
    localStorage.removeItem("darkMode");
    updateStyle();
    if (window.matchMedia) {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", updateStyle);
    }
  });

  useEffect(() => {
    performInitOperations();
    setCookie("hvLinkKycUrl", window.location.href, 1);
  }, []);

  const RedirectfromSDK = () => {
    const updatedRedirectUrl = updateRedirectUrlQueryParams(
      redirectURL,
      appId,
      transactionId,
      responseStatusRef.current
    );
    window.location = updatedRedirectUrl;
  };

  const launchCustomLinkExpiredScreen = () => {
    localStorage.removeItem("eventType");
    try {
      const config = new window.HyperKycConfig(
        jwtToken,
        workflowId,
        transactionId,
        showGetStarted
      );

      if (
        !supportCustomEndScreen ||
        !(typeof config?.setShowCustomEndScreen === "function")
      ) {
        setShowCustomEndScreen(false); // disable custom End Screen if not supported by SDK
        setLoading(false);
        setResponseReceived(true);
        return;
      }

      const endScreenMethods = {
        setShowCustomEndScreen: showCustomEndScreen,
        setIsLinkExpired: linkExpired,
        setLinkKYCRedirectionTime: redirectTime,
        setLinkKYCRedirection: RedirectfromSDK,
      };

      Object.entries(endScreenMethods).forEach(([method, value]) => {
        if (typeof config?.[method] === "function") {
          config[method](value);
        }
        if (
          !responseStatus.includes("expired") &&
          typeof config?.setInvalidEndScreenStatus === "function"
        ) {
          config?.setInvalidEndScreenStatus(responseStatus);
        }
      });
      return window.HyperKYCModule.launch(config, () => {});
    } catch (err) {
      if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.captureException(err, {
          tags: {
            source: "launch custom LinkExpired screen",
          },
          extra: {
            source: "launchCustomLinkExpiredScreen",
            identifier: queryParams.get("identifier"),
          },
        });
      }
      setResponseReceived(true);
      setResponseStatus("error");
      setLoading(false);
    }
  };

  const getContainerContent = () => {
    // Render responseModel if showCustomEndScreen is disabled or if there's an error loading SDK
    if (
      (!showCustomEndScreenRef.current || responseStatus == "error") &&
      responseReceived
    ) {
      if (redirectURL && redirectTime === 0) {
        const url = updateRedirectUrlQueryParams(
          redirectURL,
          appId,
          transactionId,
          responseStatus
        );
        window.location = url;
        return (
          <Loader
            loaderColor={loaderColor}
            loaderVersion={versionForLoaderSize}
          />
        );
      }
      return (
        <ResponseModal
          redirectURL={redirectURL}
          redirectTime={redirectTime}
          status={responseStatus}
          transactionId={transactionId}
          appId={appId}
          retryButtonVersion={versionForRetryButton}
          footerVersion={versionForFooter}
        />
      );
    }
    if (loading) {
      return (
        <Loader
          loaderColor={loaderColor}
          loaderVersion={versionForLoaderSize}
        />
      );
    }
    {
      linkExpired && showCustomEndScreenRef.current
        ? launchCustomLinkExpiredScreen()
        : startKYC();
    }
  };

  return (
    <div id="kyc_page">
      <div
        id="kyc_container"
        style={{
          width: `${containerWidth}px`,
          ...(window.innerWidth <= 600 && { width: "100%" }),
          backgroundColor: backgroundColor,
        }}
        className={enableDarkMode && isInDarkMode() ? "dark_mode" : ""}>
        {getContainerContent()}
      </div>
    </div>
  );
};

export default LinkKyc;
